<template>
  <main>
    <template v-if="isAdminGudang">
      <b-row>
        <b-col>
          <dashboard-gudang />
        </b-col>
      </b-row>
    </template>
    <template v-else-if="isFinance">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <dashboard-finance />
        </b-col>
      </b-row>
    </template>
    <template v-else-if="isOwner || isOwnerHeykama">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <dashboard-owner />
        </b-col>
      </b-row>
    </template>
    <template v-else-if="isTerapis">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <dashboard-terapis />
        </b-col>
      </b-row>
    </template>
    <template v-else-if="isCabang">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <dashboard-member />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-alert show variant="warning" class="p-1" v-if="user.level.nama_level.toUpperCase() == 'REGISTER'">
            <span class="text-dark">Akun anda belum aktif, mohon untuk menunggu proses aktivasi atau hubungi admin! </span>
          </b-alert>
        </b-col>
        <b-col sm="12" md="4" lg="3" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-success`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Affiliate</small></strong>
                <h2>
                  <strong>Rp. {{ rekapKomisi == null ? formatRupiah(0) : formatRupiah(rekapKomisi.dapat_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="3" v-if="isCabang && user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-info`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Cabang</small></strong>
                <h2>
                  <strong>Rp. {{ rekapKomisiCabang == null ? formatRupiah(0) : formatRupiah(rekapKomisiCabang.dapat_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="3" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-danger`" size="50">
                  <feather-icon size="24" icon="StopCircleIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Poin Anda</small></strong>
                <h2>
                  <strong>{{ rekapPoin ? rekapPoin.dapat_ditarik : 0 }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="4" lg="3" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-warning`" size="50">
                  <feather-icon size="24" icon="UserCheckIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Konsumen Anda</small></strong>
                <h2><strong>0</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card v-if="cekAffiliate">
        <b-row>
          <b-col md="6">
            <h4 class="text-bold">Bagaimana menggunakan program referral</h4>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />1. Salin tautan rujukan</section>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />2. Bagikan dengan teman anda dan orang lain</section>
            <section class="icon"><feather-icon class="text-success mr-1" size="20" icon="CheckCircleIcon" />3. Hasilkan uang dari langganan mereka</section>
          </b-col>
          <b-col md="6">
            <h4 class="text-bold">Tautan Referensi Anda</h4>
            Undang teman Anda dan orang lain dan dapatkan komisi dari langganan mereka
            <b-row>
              <b-col md="8">
                <b-form-input readonly class="mt-1" style="font-size: 13px" :value="'https://balanja.id/aff?member=' + user.karyawan.id"></b-form-input>
              </b-col>
              <b-col md="4">
                <b-button class="mt-1" variant="primary" @click="copyUrl('https://balanja.id/aff?member=' + user.karyawan.id)">Copy Link</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </main>
</template>
<script>
import { BAlert, BAvatar, BRow, BCol, BCard, BFormInput, BButton } from "bootstrap-vue";
import DashboardGudang from "./owner/DashboardGudang";
import DashboardFinance from "./DashboardFinance.vue";
import DashboardOwner from "./DashboardOwner.vue";
import DashboardTerapis from "./DashboardTerapis.vue";
import DashboardMember from "./DashboardMember.vue";
export default {
  data() {
    return {
      level: JSON.parse(localStorage.getItem("userData")).level,
      rekapKomisi: null,
      rekapPoin: null,
      rekapKomisiCabang: null,
    };
  },
  components: {
    DashboardMember,
    DashboardGudang,
    DashboardFinance,
    DashboardTerapis,
    DashboardOwner,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
  },
  computed: {
    myName() {
      if (this.user) {
        return this.user.karyawan ? this.user.karyawan.nama_lengkap : this.user.email;
      }

      return null;
    },

    cekAffiliate() {
      let level = this.user.level.nama_level;
      if (level.toUpperCase() == "CABANG" || level.toUpperCase() == "MARKETING") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    copyUrl(link) {
      navigator.clipboard.writeText(`${link}`);
      this.displaySuccess({
        message: "Berhasil disalin",
      });
    },
    getRekapData() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisi = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataCabang() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "cabang",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisiCabang = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataPoin() {
      let payload = {
        member_id: this.user.karyawan.id,
        type: "affiliate",
      };
      this.$store
        .dispatch("poin/getRekap", payload)
        .then((res) => {
          this.rekapPoin = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
  },
  created() {
    if (this.isOwner || this.isOwnerHeykama || this.isFinance) {
      return false;
    } else {
      this.getRekapData();
      this.getRekapDataCabang();
      this.getRekapDataPoin();
    }
  },
};
</script>

<template>
  <main>
      <b-row>
        <b-col cols="12">
          <b-alert show variant="warning" class="p-1" v-if="user.level.nama_level.toUpperCase() == 'REGISTER'">
            <span class="text-dark">Akun anda belum aktif, mohon untuk menunggu proses aktivasi atau hubungi admin! </span>
          </b-alert>
        </b-col>
        <b-col sm="12" md="6" lg="6" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-danger`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Laba tertunda</small></strong>
                <h2>
                  <strong>Rp. {{ rekapKomisi == null ? formatRupiah(0) : formatRupiah(rekapKomisi.tertunda) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="6" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-warning`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Laba belum ditarik</small></strong>
                <h2>
                  <strong>Rp. {{ rekapKomisi == null ? formatRupiah(0) : formatRupiah(rekapKomisi.dapat_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="6" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-danger`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Poin tertunda</small></strong>
                <h2>
                  <strong>Rp. {{ rekapPoin == null ? formatRupiah(0) : formatRupiah(rekapPoin.tertunda) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="6" v-if="user.level.nama_level.toUpperCase() != 'REGISTER'">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-warning`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Poin belum ditarik</small></strong>
                <h2>
                  <strong>Rp. {{ rekapPoin == null ? formatRupiah(0) : formatRupiah(rekapPoin.dapat_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
      </b-row>
  </main>
</template>
<script>
import { BAlert, BAvatar, BRow, BCol, BCard, BFormInput, BButton } from "bootstrap-vue";
import DashboardGudang from "./owner/DashboardGudang";
import DashboardFinance from "./DashboardFinance.vue";
import DashboardOwner from "./DashboardOwner.vue";
import DashboardTerapis from "./DashboardTerapis.vue";
export default {
  data() {
    return {
      level: JSON.parse(localStorage.getItem("userData")).level,
      rekapKomisi: null,
      rekapPoin: null,
      rekapKomisiCabang: null,
    };
  },
  components: {
    DashboardGudang,
    DashboardFinance,
    DashboardTerapis,
    DashboardOwner,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
  },
  computed: {
    myName() {
      if (this.user) {
        return this.user.karyawan ? this.user.karyawan.nama_lengkap : this.user.email;
      }

      return null;
    },

    cekAffiliate() {
      let level = this.user.level.nama_level;
      if (level.toUpperCase() == "CABANG" || level.toUpperCase() == "MARKETING") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    copyUrl(link) {
      navigator.clipboard.writeText(`${link}`);
      this.displaySuccess({
        message: "Berhasil disalin",
      });
    },
    getRekapData() {
      let payload = {
        member_id: this.user.member_id,
        type: "laba_seller",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisi = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataCabang() {
      let payload = {
        member_id: this.user.member_id,
        type: "laba_seller",
      };
      this.$store
        .dispatch("komisi/rekapKomisi", payload)
        .then((res) => {
          this.rekapKomisiCabang = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
    getRekapDataPoin() {
      let payload = {
        member_id: this.user.member_id,
        type: "affiliate",
      };
      this.$store
        .dispatch("poin/getRekap", payload)
        .then((res) => {
          this.rekapPoin = res;
        })
        .catch((e) => {
          console.log(e);
          // this.displayError(e);
        });
    },
  },
  created() {
    if (this.isOwner || this.isOwnerHeykama || this.isFinance) {
      return false;
    } else {
      this.getRekapData();
      // this.getRekapDataCabang();
      this.getRekapDataPoin();
    }
  },
};
</script>
